<template>
  <div class="home">
    <!-- ban 首图 -->
    <div class="ban" v-show="tabIndex == 0">
      <img src="@/assets/home/ban.png" alt="">
    </div>
    <div class="ban" v-show="tabIndex == 1">
      <img src="@/assets/home/one.png" alt="">
    </div>
    <div class="ban" v-show="tabIndex == 2">
      <img src="@/assets/home/two.png" alt="">
    </div>

    <div class="advantage" v-show="tabIndex == 0">
      <div class="advantagetext">
        <div class="advantageTitle">定制开发为什么选我们</div>
        <div class="advantageTitleText">一站链接OA系统、企业微信、在线文档、邮箱等企业内部协同应用、和重复、繁琐的工作说再见、轻松实现办公自动化</div>
      </div>
      <div class="advantageImg">
        <div class="advantageImgItem">
          <img src="@/assets/home/advantage/advantage1.png" alt="">
          <div class="advantageImgItemTitle">技术·能力强悍</div>
          <div>18年行业经验，15个技术开发部门， 每年编写程序代码上亿行、产出上千款APP。</div>
        </div>
        <div class="advantageImgItem">
          <img src="@/assets/home/advantage/advantage2.png" alt="">
          <div class="advantageImgItemTitle">周期·时短质优</div>
          <div>自主研发的项目管理系统，让项目无缝对接， 工期缩短，提升交付速度。</div>
        </div>
        <div class="advantageImgItem">
          <img src="@/assets/home/advantage/advantage3.png" alt="">
          <div class="advantageImgItemTitle">价格·公平普惠</div>
          <div>运用数据科技，满足不同行业客户的业务需求。</div>
        </div>
        <div class="advantageImgItem">
          <img src="@/assets/home/advantage/advantage4.png" alt="">
          <div class="advantageImgItemTitle">案例·多而全面</div>
          <div>深耕垂直细分场景，打造多元化解决方案， 各类型成功项目案例6000+。</div>
        </div>
      </div>
    </div>
    <div class="solution" v-show="tabIndex == 0">
      <div class="solutiontext">
        <div class="solutionTitle">提供专业的医药企业数字化解决方案</div>
        <div class="solutionTitleText">一站链接OA系统、企业微信、在线文档、邮箱等企业内部协同应用，和重复、繁琐的工作说再见，轻松实现办公自动化</div>
      </div>
      <div class="solutionImg">
        <div class="solutionImgItem">
          <img src="@/assets/home/solution/solution1.png" alt="">
          <div id="mask1">
            <div class="title">线上B2B药品商城</div>
            <div class="text">是针对电商商户的一站式电商平台建设解决方案，它能够帮助商户快速搭建自己的电商平台，解决商户在电商运营中遇到的各种问题。</div>
          </div>
        </div>
        <div class="solutionImgItem">
          <img src="@/assets/home/solution/solution2.png" alt="">
          <div id="mask1">
            <div class="title">在线问诊系统</div>
            <div class="text">
              <div>允许患者通过网页或移动应用预约在线问诊时间。</div>
              <div>提供视频、语音或文字聊天功能，使患者能够与医生进行远程交流。</div>
              <div>支持电子病历的共享和更新，确保医生能够查看患者的病史和用药情况。</div>
            </div>
          </div>
        </div>
        <div class="solutionImgItem">
          <img src="@/assets/home/solution/solution3.png" alt="">
          <div id="mask1">
            <div class="title">患者管理系统</div>
            <div class="text">
              <div>诊所可以管理患者的个人信息、预约记录和健康档案。</div>
              <div>提供自动化的预约提醒和随访通知，改善患者就医体验。</div>
              <div>分析患者数据，帮助诊所了解患者需求和服务改进点。</div>
            </div>
          </div>
        </div>
        <div class="solutionImgItem">
          <img src="@/assets/home/solution/solution4.png" alt="">
          <div id="mask1">
            <div class="title">电子处方与药品管理</div>
            <div class="text">
              <div>医生可以在线开具电子处方，直接发送至患者或药店。</div>
              <div>整合药品数据库，确保处方的准确性和合规性。</div>
              <div>监控药品库存和使用情况，优化药品采购和管理流程。</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="product" v-show="tabIndex == 0 || tabIndex == 1">
      <img src="@/assets/home/product.png" alt="">
    </div>
    <div class="product" v-show="tabIndex == 0 || tabIndex == 2">
      <img src="@/assets/home/three.png" alt="">
    </div>
    <div class="solution" style="background-color: #FFF;padding-top: 0;margin-top: 70px;"  v-show="tabIndex == 0">
      <div class="solutiontext">
        <div class="solutionTitle">我们的服务</div>
        <div class="solutionTitleText">我们专注于网站建设，网站推广等各种网络营销产品</div>
      </div>
      <div class="solutionImg">
        <div class="solutionImgItem">
          <img src="@/assets/home/giveServiceTo/giveServiceTo1.png" alt="">
        </div>
        <div class="solutionImgItem">
          <img src="@/assets/home/giveServiceTo/giveServiceTo2.png" alt="">
        </div>
        <div class="solutionImgItem">
          <img src="@/assets/home/giveServiceTo/giveServiceTo3.png" alt="">
        </div>
        <div class="solutionImgItem">
          <img src="@/assets/home/giveServiceTo/giveServiceTo4.png" alt="">
        </div>
      </div>
    </div>
    <div class="product" style="padding-top: 0;" v-show="tabIndex == 0">
      <img src="@/assets/home/flowPath.png" alt="">
    </div>
    <div class="product" v-show="tabIndex == 0">
      <img src="@/assets/home/briefIntroduction.png" alt="">
    </div>
    <div class="solution" style="background-color: #FFF;padding-top: 70px;margin-top: 70px;" v-show="tabIndex == 0">
      <div class="backcolor"></div>
      <div class="solutiontext">
        <div class="solutionTitle">经营理念</div>
        <div class="solutionTitleText">创造精品软件，提供满意服务，与合作伙伴携手创业。</div>
      </div>
      <div class="solutionImg">
        <div class="solutionImgItem">
          <img src="@/assets/home/idea/idea1.png" alt="">
        </div>
        <div class="solutionImgItem">
          <div style="height: 70px;"></div>
          <img src="@/assets/home/idea/idea2.png" alt="">
        </div>
        <div class="solutionImgItem">
          <img src="@/assets/home/idea/idea3.png" alt="">
        </div>
        <div class="solutionImgItem">
          <div style="height: 70px;"></div>
          <img src="@/assets/home/idea/idea4.png" alt="">
        </div>
      </div>
    </div>
    <div class="product" style="padding-top: 0;" v-show="tabIndex == 0">
      <img src="@/assets/home/culture.png" alt="">
    </div>
    <div class="product" v-show="tabIndex == 3">
      <img src="@/assets/home/contactUs.png" alt="">
    </div>



    <div class="bottom">
      <div class="bottombckor"></div>
      <div class="bottomcon">
        <div class="bottomconui">
          <div class="bottomconImg">
            <img src="@/assets/home/zhiyao.png" alt="">
          </div>
          <el-divider></el-divider>
          <div class="filings">
            <div class="filingstitle">
              <a href="https://beian.miit.gov.cn/" target="_blank">备案号：陕ICP备2024024590号-1</a>
            </div>
            <div class="filingsText">
              <span>隐私政策</span>
              <span>服务条款</span>
              <span>Cookie设置</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      
    }
  }
}
</script>

<style lang="scss" scoped>
.ban {
  width: 100%;
  img {
    width: 100%;
  }
}
.advantage {
  text-align: center;
  .advantagetext {
    margin: 0 auto;
    margin-top: 120px;
    width: 680px;
    font-weight: 500;
    font-size: 46px;
    color: #000000;
    .advantageTitleText {
      font-weight: 400;
      font-size: 20px;
      color: #9EA1AB;
      margin-top: 20px;
      line-height: 28px;
    }
  }
  .advantageImg {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
    padding: 0 12%;
    .advantageImgItem {
      width: 15%;
      img {
        width: 100%;
      }
      div {
        margin: 0 auto;
        width: 72%;
        font-weight: 400;
        font-size: 16px;
        color: #939292;
      }
      .advantageImgItemTitle {
        font-weight: bold;
        font-size: 20px;
        color: #0D152E;
        margin: 10px auto;
      }
    }
  }
}
.solution {
  position: relative;
  text-align: center;
  background-color: #E9EEF3;
  margin-top: 120px;
  padding-top: 70px;
  padding-bottom: 70px;
  z-index: 20;
  .backcolor {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    background-color: #E9EEF3;
    z-index: 1;
  }
  .solutiontext {
    position: relative;
    text-align: center;
    font-weight: 500;
    font-size: 46px;
    color: #000000;
    z-index: 20;
    .solutionTitleText {
      width: 680px;
      font-weight: 400;
      font-size: 20px;
      color: #9EA1AB;
      margin: 0 auto;
      margin-top: 20px;
      line-height: 28px;
    }
  }
  .solutionImg {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
    padding: 0 12%;
    z-index: 20;
    .solutionImgItem {
      width: 20%;
      position: relative;
      img {
        width: 100%;
      }
    }
    .solutionImgItem:hover #mask1 {
      opacity: 1;
    }
  }
}
.product {
  padding: 0 12%;
  padding-top: 120px;
  img {
    width: 100%;
  }
}
.bottom {
  position: relative;
  .bottombckor {
    position: relative;
    padding: 0 12%;
    padding-top: 48px;
    margin-top: 70px;
    height: 400px;
    background-color: #3684EC;
  }
  .bottomcon {
    position: absolute;
    top: 48px;
    left: 12%;
    width: 76%;
    height: 500px;
    background: #F4F5F7;
    .bottomconui {
      padding: 0 6%;
      .bottomconImg {
        width: 50%;
        margin: 0 auto;
        margin-top: 10%;
        margin-bottom: 60px;
        img {
          width: 50%;
        }
      }
      .filings {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .filingstitle {
          font-weight: 600;
          font-size: 14px;
          color: #1D2129;
        }
        .filingsText {
          font-size: 14px;
          color: #1D2129;
          line-height: 20px;
          span {
            margin-left: 20px;
          }
        }
      }
    }
    
  }
}






#mask1{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(233, 231, 231, 0.582);
  opacity: 0;
  z-index: 999;
  text-align: left;
  .title {
    width: 70%;
    margin: 0 auto;
    margin-top: 40px;
    font-weight: bold;
    font-size: 20px;
    color: #FFFFFF;
  }
  .text {
    width: 70%;
    margin: 0 auto;
    margin-top: 10px;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
  }
}
</style>
