<template>
  <div class="home">
    <el-container>
      <el-header>
        <div class="headerTop">
          <div class="logo">
            <img src="@/assets/logo.png" alt="">
          </div>
          <div class="tabTag">
            <div :style="tabIndex == index ? 'color: #006CDA': ''" v-for="(item, index) in tabList" :key="index" @click="tabClick(index)">{{ item.name }}</div>
          </div>
          <div></div>
        </div>
      </el-header>
      <el-main>
        <Home :tabIndex="tabIndex"></Home>
      </el-main>
      <!-- <el-footer>Footer</el-footer> -->
    </el-container>
  </div>
</template>

<script>
// @ is an alias to /src
import Home from './home/index.vue'

export default {
  // name: 'HomeView',
  components: {
    Home
  },
  data() {
    return {
      tabIndex: 0,
      tabList: [
        { name: '首页', index: 0},
        { name: '产品', index: 1},
        { name: '解决方案', index: 2},
        { name: '联系我们', index: 3},
      ]
    }
  },
  methods: {
    tabClick(val) {
      this.tabIndex = val
    }
  }
}
</script>

<style lang="scss">
  
  .el-header {
    height: 80px !important;
    .headerTop {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 80px;
      .logo {
        width: 200px;
        img {
          width: 100%;
        }
      }
    }
    .tabTag {
      display: flex;
      align-items: center;
      div {
        cursor: pointer;
        font-size: 16px;
        color: #000000;
        font-weight: bold;
        height: 80px;
        line-height: 80px;
        padding: 0 18px;
      }
    }
    .el-footer {
      background-color: #B3C0D1;
      color: #333;
      text-align: center;
      line-height: 60px;
    }
  } 
  
  .el-aside {
    background-color: #D3DCE6;
    color: #333;
    text-align: center;
    line-height: 200px;
  }
  
  .el-main {
    background-color: #FFF !important;
    color: #333;
    text-align: center;
    height: calc(100vh - 80px);
    padding: 0 !important;
  }
</style>
